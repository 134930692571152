import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import GitHubButton from 'react-github-btn'; // eslint-disable-line import/namespace, import/default, import/no-named-as-default, import/no-deprecated, import/no-named-as-default-member
import config from '../../config';
import Link from './Link';
import Search from './Search';
import SidebarLayout from './SidebarLayout';
import './styles.css';

const isSearchEnabled = config.header.search && config.header.search.enabled ? true : false;

const searchIndices = [];
if (isSearchEnabled && config.header.search.indexName) {
  searchIndices.push({ name: `${config.header.search.indexName}`, title: `Results`, hitComp: `PageHit` });
}

const Header = ({ location }) => (
  <StaticQuery
    query={graphql`
      query headerTitleQuery {
        site {
          siteMetadata {
            headerTitle
            gitUrl
            helpUrl
            tweetText
            logo {
              link
              image
            }
            headerLinks {
              link
              text
            }
          }
        }
      }
    `}
    render={(data) => {
      const {
        site: {
          siteMetadata: { headerTitle, gitUrl, helpUrl, tweetText, logo, headerLinks },
        },
      } = data;
      const finalLogoLink = logo.link !== '' ? logo.link : '/';

      return (
        <div className={'navBarWrapper'}>
          <nav className={'navbar navbar-default navBarDefault'}>
            <div className={'navbar-header navBarHeader'}>
              <Link className={'navbar-brand navBarBrand'} to={finalLogoLink}>
                {logo.image !== '' ? (
                  <img alt={'logo'} className={'img-responsive'} src={logo.image} />
                ) : (
                  <img alt={'logo'} className={'img-responsive'} src={require('./images/logo.svg')} />
                )}
                {/* eslint-disable-next-line react/no-danger */}
                <div className={'headerTitle'} dangerouslySetInnerHTML={{ __html: headerTitle }} />
              </Link>
              <button
                aria-controls="navbar"
                aria-expanded="false"
                className={'navbar-toggle collapsed navBarToggle'}
                data-target="#navbar"
                data-toggle="collapse"
                type="button"
              >
                <span className={'sr-only'}>Toggle navigation</span>
                <span className={'icon-bar'} />
                <span className={'icon-bar'} />
                <span className={'icon-bar'} />
              </button>
            </div>
            {isSearchEnabled ? (
              <div className={'searchWrapper hidden-xs navBarUL'}>
                <Search collapse indices={searchIndices} />
              </div>
            ) : null}
            <div className={'navbar-collapse collapse navBarCollapse'} id="navbar">
              <div className={'visible-xs'}>
                <SidebarLayout location={location} />
                <hr />
                {isSearchEnabled ? (
                  <div className={'searchWrapper navBarUL'}>
                    <Search collapse indices={searchIndices} />
                  </div>
                ) : null}
              </div>
              <ul className={'nav navbar-nav navBarUL navBarNav navbar-right navBarULRight'}>
                {headerLinks.map((link, key) => {
                  if (link.link !== '' && link.text !== '') {
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <li key={key}>
                        <a
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{ __html: link.text }}
                          href={link.link}
                          rel="noopener noreferrer"
                          target="_blank"
                        />
                      </li>
                    );
                  }

                  return undefined;
                })}
                {helpUrl !== '' ? (
                  <li>
                    <a href={helpUrl}>
                      <img alt={'Help icon'} src={require('./images/help.svg')} />
                    </a>
                  </li>
                ) : null}
                {tweetText !== '' || gitUrl !== '' ? <li className="divider hidden-xs" /> : null}
                {tweetText !== '' ? (
                  <li>
                    <a
                      href={`https://twitter.com/intent/tweet?&text=${tweetText}`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img alt={'Twitter'} className={'shareIcon'} src={require('./images/twitter.svg')} />
                    </a>
                  </li>
                ) : null}
                {gitUrl !== '' ? (
                  <li className={'githubBtn'}>
                    <GitHubButton aria-label="Star on GitHub" data-show-count="true" href={gitUrl}>
                      Star
                    </GitHubButton>
                  </li>
                ) : null}
              </ul>
            </div>
          </nav>
        </div>
      );
    }}
  />
);

export default Header;
