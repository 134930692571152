/* eslint-disable no-process-env */
module.exports = {
  gatsby: {
    // pathPrefix: '/coding-standard',
    // siteUrl: 'https://koober-sas.gitlab.io',
    pathPrefix: '/',
    siteUrl: 'https://tech.koober.com',
    gaTrackingId: null,
  },
  header: {
    logo: 'https://assets.gitlab-static.net/uploads/-/system/group/avatar/6210743/koober-logo.jpg?width=64',
    logoLink: '#',
    title: 'Koober Coding Standards',
    gitUrl: 'https://gitlab.com/koober-sas/coding-standard',
    helpUrl: '',
    tweetText: '',
    links: [{ text: '', link: '' }],
    search: {
      enabled: false,
      indexName: '',
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
    },
  },
  sidebar: {
    forcedNavOrder: [],
    links: [
      { text: 'NPM (Open source)', link: 'https://www.npmjs.com/org/koober' },
      { text: 'GitLab (Open source)', link: 'https://gitlab.com/koober-sas/' },
      { text: 'Koober.com', link: 'https://koober.com' },
    ],
    frontline: true,
    ignoreIndex: true,
  },
  siteMetadata: {
    title: 'Koober Coding Standards',
    description: 'Documentation built with mdx.',
    ogImage: null,
    docsLocation: 'https://gitlab.com/koober-sas/coding-standard/tree/master/content', // eslint-disable-line unicorn/prevent-abbreviations
    favicon: 'https://assets.gitlab-static.net/uploads/-/system/group/avatar/6210743/koober-logo.jpg?width=64',
  },
};
