import * as React from 'react';

const Anchor = ({ children: link, ...properties }) => {
  if (link) {
    return (
      <a href={properties.href} {...properties}>
        {link}
      </a>
    );
  }

  return null;
};

export default Anchor;
