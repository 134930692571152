import React from 'react';
import OpenedSvg from '../images/Opened';
import ClosedSvg from '../images/Closed';
import config from '../../../config';
import Link from '../Link';

const TreeNode = ({ className = '', setCollapsed, collapsed, url, title, items, ...rest }) => {
  const isCollapsed = collapsed[url];
  const collapse = () => {
    setCollapsed(url);
  };
  const hasChildren = items.length !== 0;
  let location;
  if (typeof document != 'undefined') {
    location = document.location;
  }
  const active = location && (location.pathname === url || location.pathname === config.gatsby.pathPrefix + url);
  const calculatedClassName = `${className} item ${active ? 'active' : ''}`;

  return (
    <li className={calculatedClassName}>
      {!config.sidebar.frontLine && title && hasChildren ? (
        <button className="collapser" onClick={collapse} type="button">
          {!isCollapsed ? <OpenedSvg /> : <ClosedSvg />}
        </button>
      ) : null}

      {title && <Link to={url}>{title}</Link>}

      {!isCollapsed && hasChildren ? (
        <ul>
          {items.map((item) => (
            <TreeNode key={item.url} collapsed={collapsed} setCollapsed={setCollapsed} {...item} />
          ))}
        </ul>
      ) : null}
    </li>
  );
};
export default TreeNode;
