import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import styled, { css } from 'styled-components';
import { Search } from '@styled-icons/fa-solid/Search';

const SearchIcon = styled(Search)`
  width: 1em;
  pointer-events: none;
  margin-right: 10px;
`;
const focus = css`
  background: white;
  color: ${(properties) => properties.theme.darkBlue};
  cursor: text;
  width: 5em;
  + ${SearchIcon} {
    color: ${(properties) => properties.theme.darkBlue};
    margin: 0.3em;
  }
`;
const collapse = css`
  width: 0;
  cursor: pointer;
  color: ${(properties) => properties.theme.lightBlue};
  + ${SearchIcon} {
    color: white;
  }
  ${(properties) => properties.focus && focus}
  margin-left: ${(properties) => (properties.focus ? `-1.6em` : `-1em`)};
  padding-left: ${(properties) => (properties.focus ? `1.6em` : `1em`)};
  ::placeholder {
    color: ${(properties) => properties.theme.gray};
  }
`;
const expand = css`
  background: ${(properties) => properties.theme.veryLightGray};
  width: 6em;
  margin-left: -1.6em;
  padding-left: 1.6em;
  + ${SearchIcon} {
    margin: 0.3em;
  }
`;
const Input = styled.input`
  outline: none;
  border: none;
  font-size: 1em;
  background: white;
  transition: ${(properties) => properties.theme.shortTrans};
  border-radius: ${(properties) => properties.theme.smallBorderRadius};
  {highlight-next-line}
  ${(properties) => (properties.collapse ? collapse : expand)};
`;
const Form = styled.form`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`;

export default connectSearchBox(({ refine, ...rest }) => {
  const searchCustom = '';
  const preventSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <Form className={'formElement'} onSubmit={preventSubmit}>
      <SearchIcon />
      <Input
        aria-label="Search"
        className={`searchInput ${searchCustom}`}
        onChange={(event) => refine(event.target.value)}
        placeholder="Search"
        type="text"
        {...rest}
      />
    </Form>
  );
});
