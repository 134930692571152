import styled from 'react-emotion';

const notificationTypes = {
  warning: {
    dark: '#daa520',
    light: '#ffefd5',
  },
  error: {
    dark: '#b22222',
    light: '#bc8f8f',
  },
  info: {
    dark: '#663399',
    light: '#FFFFFF',
  },
};

const getColor = (type = 'info', shade = 'dark') => notificationTypes[type][shade];

const Notification = styled('section')`
  color: ${(properties) => getColor(properties.type, 'light')};
  background: ${(properties) => getColor(properties.type)};
  width: 100%;
  padding: 1rem;
  text-align: center;
`;

export default Notification;
