import React, { useState } from 'react';
import config from '../../../config';
import TreeNode from './TreeNode';

const calculateTreeData = (edges) => {
  const originalData = config.sidebar.ignoreIndex
    ? edges.filter(
        ({
          node: {
            fields: { slug },
          },
        }) => slug !== '/'
      )
    : edges;
  const tree = originalData.reduce(
    (
      accumulator,
      {
        node: {
          fields: { slug, title },
        },
      }
    ) => {
      const parts = slug.split('/');
      let { items: previousItems } = accumulator;
      for (const part of parts.slice(1, -1)) {
        let temporary_ = previousItems.find(({ label }) => label === part);
        if (temporary_) {
          if (!temporary_.items) {
            temporary_.items = [];
          }
        } else {
          temporary_ = { label: part, items: [] };
          previousItems.push(temporary_);
        }
        previousItems = temporary_.items;
      }
      const existingItem = previousItems.find(({ label }) => label === parts[parts.length - 1]);
      if (existingItem) {
        existingItem.url = slug;
        existingItem.title = title;
      } else {
        previousItems.push({
          label: parts[parts.length - 1],
          url: slug,
          items: [],
          title,
        });
      }

      return accumulator;
    },
    { items: [] }
  );
  const {
    sidebar: { forcedNavOrder = [] },
  } = config;
  const temporary = [...forcedNavOrder];
  temporary.reverse();

  return temporary.reduce((accumulator, slug) => {
    const parts = slug.split('/');
    let { items: previousItems } = accumulator;
    for (const part of parts.slice(1, -1)) {
      let temporaryItem = previousItems.find(({ label }) => label === part);
      if (temporaryItem) {
        if (!temporaryItem.items) {
          temporaryItem.items = [];
        }
      } else {
        temporaryItem = { label: part, items: [] };
        previousItems.push(temporaryItem);
      }
      previousItems = temporaryItem.items;
    }
    // sort items alphabetically.
    previousItems.forEach((item) => {
      item.items = item.items.sort(function (leftItem, rightItem) {
        if (leftItem.label < rightItem.label) return -1;
        if (leftItem.label > rightItem.label) return 1;

        return 0;
      });
    });
    const index = previousItems.findIndex(({ label }) => label === parts[parts.length - 1]);
    accumulator.items.unshift(previousItems.splice(index, 1)[0]);

    return accumulator;
  }, tree);
};

const Tree = ({ edges }) => {
  const [treeData] = useState(() => {
    return calculateTreeData(edges);
  });
  const [collapsed, setCollapsed] = useState({});
  const toggle = (url) => {
    setCollapsed({
      ...collapsed,
      [url]: !collapsed[url],
    });
  };

  return (
    <TreeNode
      className={`${config.sidebar.frontLine ? 'showFrontLine' : 'hideFrontLine'} firstLevel`}
      collapsed={collapsed}
      setCollapsed={toggle}
      {...treeData}
    />
  );
};

export default Tree;
