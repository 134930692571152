/* eslint-disable unicorn/prevent-abbreviations */
import React, { useState, useEffect, createRef } from 'react';
import { InstantSearch, Index, Hits, Configure, connectStateResults } from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import styled from 'styled-components';
// import { Search as StyledIconSearch } from 'styled-icons/fa-solid/Search';
import config from '../../../config';
import { PoweredBy } from './styles';
import Input from './input';
import * as hitComponents from './PageHit';
import '../styles.css';

/* const SearchIcon = styled(StyledIconSearch)`
  width: 1em;
  pointer-events: none;
`; */

const HitsWrapper = styled.div`
  display: ${(properties) => (properties.show ? `grid` : `none`)};
  max-height: 80vh;
  overflow: scroll;
  z-index: 2;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  right: 0;
  top: calc(100% + 0.5em);
  width: 80vw;
  max-width: 30em;
  box-shadow: 0 0 5px 0;
  padding: 0.7em 1em 0.4em;
  background: white;
  border-radius: ${(properties) => properties.theme.smallBorderRadius};
  > * + * {
    padding-top: 1em !important;
    border-top: 2px solid ${(properties) => properties.theme.darkGray};
  }
  li + li {
    margin-top: 0.7em;
    padding-top: 0.7em;
    border-top: 1px solid ${(properties) => properties.theme.lightGray};
  }
  * {
    margin-top: 0;
    padding: 0;
    color: black !important;
  }
  ul {
    list-style: none;
  }
  mark {
    color: ${(properties) => properties.theme.lightBlue};
    background: ${(properties) => properties.theme.darkBlue};
  }
  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.3em;
    h3 {
      color: black;
      background: ${(properties) => properties.theme.gray};
      padding: 0.1em 0.4em;
      border-radius: ${(properties) => properties.theme.smallBorderRadius};
    }
  }
  h3 {
    color: black;
    margin: 0 0 0.5em;
  }
  h4 {
    color: black;
    margin-bottom: 0.3em;
  }
`;
const Root = styled.div`
  position: relative;
  display: grid;
  grid-gap: 1em;
`;
/*
const focus = css`
  background: white;
  color: ${properties => properties.theme.darkBlue};
  cursor: text;
  width: 5em;
  + ${SearchIcon} {
    color: ${properties => properties.theme.darkBlue};
    margin: 0.3em;
  }
`; */

const Results = connectStateResults(({ searchState: state, searchResults, children }) =>
  searchResults && searchResults.query && searchResults.nbHits > 0 ? children : `No results for '${state.query}'`
);
/*
const Stats = connectStateResults(
  ({ searchResults: res }) => res && res.query && res.nbHits > 0 && `${res.nbHits} result${res.nbHits > 1 ? `s` : ``}`
); */

const useClickOutside = (reference, handler, events = [`mousedown`, `touchstart`]) => {
  const detectClickOutside = (event) => !reference.current.contains(event.target) && handler();
  useEffect(() => {
    for (const event of events) document.addEventListener(event, detectClickOutside);

    return () => {
      for (const event of events) document.removeEventListener(event, detectClickOutside);
    };
  });
};

export default function Search({ indices, collapse, hitsAsGrid }) {
  const reference = createRef();
  const [query, setQuery] = useState(``);
  const [focus, setFocus] = useState(false);
  const searchClient = algoliasearch(config.header.search.algoliaAppId, config.header.search.algoliaSearchKey);
  useClickOutside(reference, () => setFocus(false));
  const displayResult = query.length > 0 && focus ? 'showResults' : 'hideResults';

  return (
    <InstantSearch
      indexName={indices[0].name}
      onSearchStateChange={({ query: _ }) => setQuery(_)}
      root={{ Root, props: { ref: reference } }}
      searchClient={searchClient}
    >
      <Input onFocus={() => setFocus(true)} {...{ collapse, focus }} />
      <HitsWrapper asGrid={hitsAsGrid} className={`hitWrapper ${displayResult}`} show={query.length > 0 && focus}>
        {indices.map(({ name, title, hitComp }) => (
          <Index key={name} indexName={name}>
            <Results>
              {/* eslint-disable-next-line import/namespace */}
              <Hits hitComponent={hitComponents[hitComp](() => setFocus(false))} />
            </Results>
          </Index>
        ))}
        <PoweredBy />
      </HitsWrapper>
      <Configure hitsPerPage={5} />
    </InstantSearch>
  );
}
