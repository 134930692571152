import React from 'react';
import Heading from '../Heading';
import Text from './Text';
import Code from './Code';
import CodeBlock from './CodeBlock';
import Pre from './Pre';
import Anchor from './Anchor';

export function headerId(node) {
  const nodeText = typeof node === 'string' ? node : '';

  return nodeText.replace(/\s+/g, '-').replace(/\./, '').toLowerCase();
}

/* eslint-disable react/display-name */
export default {
  h1: (properties) => <Heading id={headerId(properties.children)} {...properties} fontSize={[5, 6]} is="h1" />,
  h2: (properties) => <Heading id={headerId(properties.children)} {...properties} fontSize={[4]} is="h2" />,
  h3: (properties) => <Heading id={headerId(properties.children)} {...properties} fontSize={3} is="h3" />,
  h4: (properties) => <Heading id={headerId(properties.children)} {...properties} fontSize={2} is="h4" />,
  h5: (properties) => <Heading id={headerId(properties.children)} {...properties} fontSize={1} is="h5" />,
  h6: (properties) => <Heading id={headerId(properties.children)} {...properties} fontSize={0} is="h6" />,
  p: (properties) => <Text {...properties} is="p" lineHeight={1.625} mb={4} mt={3} />,
  pre: Pre,
  code: CodeBlock,
  inlineCode: (properties) => <Code {...properties} />,
  a: (properties) => <Anchor {...properties} />,
  // TODO add `img`
  // TODO add `blockquote`
  // TODO add `ul`
  // TODO add `li`
  // TODO add `table`
};
